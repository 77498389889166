<template>
  <div
    class="flex flex-col lg:flex-row-reverse space-y-2 lg:space-y-0 justify-between"
  >
    <right-sidebar class="w-full lg:w-1/5" />
    <div class="w-full lg:w-4/5 lg:mr-1">
      <div class="border rounded bg-white p-2">
        <div class="flex flex-col space-y-2 mb-4">
          <h1 class="text-base">Offene Mitarbeitergespräche</h1>
          <data-repeater
            :columns="pendingEvaluations.columns"
            :rows="pendingEvaluations.rows"
            :actions="actions"
            :permission="permission"
            :height="'h-64'"
          />
        </div>
        <div class="flex flex-col space-y-2 mt-4">
          <h1 class="text-base">Erledigte Mitarbeitergespräche</h1>
          <data-repeater
            :columns="processedEvaluations.columns"
            :rows="processedEvaluations.rows"
            :actions="actions"
            :permission="permission"
            :height="'h-64'"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import axios from "@/utils/axios";

const tempPerformanceReview = {
  resource: "performance-evaluation/setting/ratingscale",
  columns: [
    {
      label: "Mitarbeiter",
      field: ["employe.personal.first_name", "employe.personal.last_name"],
      postfix: "",
      type: "text",
      headerClass: "class-in-header second-class",
      headerAlign: "left",
      width: "20",
    },
    {
      label: "Status",
      field: ["status"],
      headerAlign: "left",
      width: "20",
      type: "status",
      postfix: "",
    },
    {
      label: "Erledigt von",
      field: [
        "prepared_by[0].personal.personal_avatar",
        "prepared_by[1].personal.personal_avatar",
        "prepared_by[2].personal.personal_avatar",
      ],
      headerAlign: "left",
      width: "15",
      check: "completed",
      type: "image",
    },
    {
      label: "Datum",
      field: ["preparation_deadline"],
      headerAlign: "left",
      width: "20",
      type: "date",
    },
    {
      label: "",
      field: ["prepare_performance_evaluation"],
      type: "link",
      linkTo: `personal/performance-evaluation/prepare`,
      link: ["id"],
      width: "15",
      postfix: "",
    },
    {
      label: "",
      field: ["final_performance_evaluation"],
      type: "link",
      link: ["id"],
      linkTo: `personal/performance-evaluation/assesment`,
      width: "15",
      postfix: "",
      event: "download-evaluation",
    },

    { label: "", field: ["id"], type: "checkbox", width: "10" },
  ],
  rows: [],
};

export default {
  name: "all-employee-evaluation",
  title: "Performance Evaluation",
  data() {
    return {
      pendingEvaluations: { ...tempPerformanceReview },
      processedEvaluations: { ...tempPerformanceReview },
      actions: {
        index: null,
        list: ["delete"],
        callback: (state) => {
          this.$bus.$emit("show-modal", {
            show: true,
            title: "Mitarbeitergespräche entfernen",
            component: "remove-dialog",
            request: state.type,
            message: `Möchten Sie wirklich den Mitarbeitergespräche entfernen?`,
            resource: `/performance-evaluation/${state.payload.id}/`,
            data: state.payload,
            maxWidth: "w-full md:w-1/3",
            event: "reload-performance-reviews",
            type: state.type,
          });
        },
      },
      breadcrumbs: {
        title: "Alle Mitarbeitergespräche",
        actions: {
          title: this.can(
            "Personal",
            "Mitarbeitergespräche",
            "Mitarbeitergespräche",
            "edit",
            ["All"]
          )
            ? "Mitarbeitergespräch auslösen"
            : null,
          component: "new-performance-review",
          resource: "/performance-evaluation/",
          maxWidth: "w-full md:w-1/2",
          dialogType: "wizard",
          event: "reload-performance-reviews",
        },
        permission: {
          module: "Personal",
          subModule: "Mitarbeitergespräche",
          childModule: "Mitarbeitergespräche",
          operation: "edit",
          options: ["All"],
        },
      },
      permission: {
        module: "Personal",
        subModule: "Mitarbeitergespräche",
        childModule: "Mitarbeitergespräche",
        operation: "edit",
        options: ["All"],
      },
    };
  },
  mounted() {
    this.init();

    this.$bus.$off("download-evaluation");
    this.$bus.$off("reload-performance-reviews");
    this.$bus.$on("download-evaluation", this.downloadEvaluation);
    this.$bus.$on("reload-performance-reviews", this.init);
  },
  methods: {
    init() {
      this.$emit("breadcrumbs", this.breadcrumbs);
      axios.get(`performance-evaluation/?view=all`).then((response) => {
        this.pendingEvaluations.rows = _.map(
          _.filter(response.data.results, (review) =>
            review.status.includes("preparation")
          ),
          (review) => {
            return {
              ...review,
              preparation_deadline:
                this.dateDiff(review.preparation_deadline) <= 0
                  ? review.review_deadline
                  : review.preparation_deadline,

              prepare_performance_evaluation:
                review.employe.personal.id === this.profile.personal.id ||
                review.supervisor.personal.id === this.profile.personal.id
                  ? "Gespräch vorbereiten"
                  : "Gespräch vorbereiten",

              final_performance_evaluation:
                review.employe.personal.id === this.profile.personal.id ||
                review.supervisor.personal.id === this.profile.personal.id
                  ? review.status === "preparation_done"
                    ? this.profile.personal.id === review.supervisor.personal.id
                      ? "Gespräch beginnen"
                      : ""
                    : ""
                  : "",
              employe: {
                ...review.employe,
                personal: {
                  ...review.employe.personal,
                  personal_avatar:
                    review.employe.status === "preparation_done"
                      ? review.employe.personal.personal_avatar || null
                      : "-",
                },
              },
              supervisor: {
                ...review.supervisor,
                personal: {
                  ...review.supervisor.personal,
                  personal_avatar:
                    review.supervisor.status === "preparation_done"
                      ? review.supervisor.personal.personal_avatar || null
                      : "-",
                },
              },
              expired: `${this.getExpired(
                review.preparation_deadline,
                review.review_deadline
              )}`,
              prepared_by: this.preparedBy(review),
            };
          }
        );
        this.processedEvaluations.rows = _.map(
          _.filter(response.data.results, (review) => review.status === "done"),
          (review) => {
            return {
              ...review,
              final_performance_evaluation: "PDF erstellen",
              linkTo: `performance-evaluation/pdf`,
              type: "pdf",
              employe: {
                ...review.employe,
                personal: {
                  ...review.employe.personal,
                  personal_avatar:
                    review.employe.personal.personal_avatar || null,
                },
              },
              supervisor: {
                ...review.supervisor,
                personal: {
                  ...review.supervisor.personal,
                  personal_avatar:
                    review.supervisor.personal.personal_avatar || null,
                },
              },
              expired: `text-grey-900`,
              preparation_deadline: review.completed_at,
              prepared_by: this.preparedBy(review),
            };
          }
        );
      });
    },
    getExpired(date1, date2) {
      if (this.dateDiff(date1) > 1) {
        if (this.dateDiff(date1) > 3) {
          return "text-green-400";
        } else {
          return "text-yellow-500";
        }
      } else if (this.dateDiff(date1) <= 1) {
        return "text-red-500";
      }

      if (this.dateDiff(date2) > 1) {
        if (this.dateDiff(date2) > 3) {
          return "text-green-400";
        } else {
          return "text-yellow-500";
        }
      } else if (this.dateDiff(date2) <= 1) {
        return "text-red-500";
      }
    },
  },
};
</script>
